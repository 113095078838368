<template>
  <div class="digital">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">弹性福利一站式服务，让服务更有温度</h1>
        <h1 class="banner-two-title">福利方案 + 技术支持 + 供应保障 + 运营售后</h1>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 福利行业现状 -->
    <div class="digital-nows">
      <div class="container mx-auto">
        <h1 class="three-leave-title">福利行业现状</h1>
        <div class="content grid grid-cols-3 grid-rows-2 gap-4">
          <div class="card flex justify-center flex-col" v-for="(item, index) in fuliList" :key="index">
            <img :src="item.icon" />
            <p class="title">{{ item.title }}</p>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 深度打造弹性员工福利解决方案 -->
    <div class="digital-solution">
      <div class="container mx-auto">
        <h1 class="three-leave-title">深度打造弹性员工福利解决方案</h1>
        <div class="content grid grid-cols-4 grid-rows-1 gap-2">
          <div
            class="card flex justify-center flex-col"
            v-for="(item, index) in solutionList"
            :key="index"
            @mouseenter="onMouseEnter(index)"
          >
            <div class="card-image" :style="{ backgroundImage: 'url(' + item.bgImg + ')' }">
              <div :class="[activeIndex == index && 'mask-selected', 'mask']">
                <div class="mask-content">
                  <p class="mask-content-title">弹性福利体系</p>
                  <p class="mask-content-title">满足不同福利需求</p>
                  <p class="mask-content-title">提升员工敬业度</p>
                  <p class="mask-content-desc">
                    在弹性福利体系下，同样的投入可 以满足不同员工的福利需求，使既 定的有效性大大提高，有效提升员
                    工敬业度与企业文化。
                  </p>
                </div>
              </div>
            </div>
            <p :class="[activeIndex == index && 'title-selected', 'title']">需求化个性</p>
            <div class="flex justify-center">
              <div :class="[activeIndex == index && 'icon-right-selected', 'icon-right']"></div>
            </div>
          </div>
        </div>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 创新福利管理理念，全面提升福利价值 -->
    <div class="digital-manage">
      <div class="container mx-auto">
        <h1 class="three-leave-title">创新福利管理理念，全面提升福利价值</h1>
        <div class="content grid grid-cols-3 grid-rows-1 gap-2">
          <div class="card flex justify-center flex-col" v-for="(item, index) in manageList" :key="index">
            <div class="flex align-center">
              <img :src="item.icon" />
              <p :class="['digital-card-title', item.class]">墨鱼优选福利商城</p>
            </div>
            <p class="digital-card-desc">
              在弹性福利体系下，同样的投入可以满足不同员工 的福利需求，使既定的有效性大大提高，有效提升
              员工敬业度与企业文化。在弹性福利体系下，同样 的投入可以满足不同员工的福利需求，使既定的有
              效性大大提高，有效提升员工敬业度与企业文化。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: 0,
      fuliList: [
        {
          icon: require('@/assets/img/digital/digital-one.png'),
          title: '需求化个性',
          desc: '众口难调，无法满足个性化需求，员工满意度低'
        },
        {
          icon: require('@/assets/img/digital/digital-two.png'),
          title: '员工感知度低',
          desc: '员工的福利参与率不高，福利感知度低'
        },
        {
          icon: require('@/assets/img/digital/digital-three.png'),
          title: '耗时耗力',
          desc: '花大量时间选品、采购、寄送，专人负责福利管理，耗时耗力'
        },
        {
          icon: require('@/assets/img/digital/digital-fore.png'),
          title: '功能有限',
          desc: '福利系统功能有限，操作和维护复杂'
        },
        {
          icon: require('@/assets/img/digital/digital-five.png'),
          title: '方案缺乏',
          desc: '组织文化建设，缺乏合适的落实方式'
        },
        {
          icon: require('@/assets/img/digital/digital-six.png'),
          title: '无法降低税负',
          desc: '企业福利补贴发放较频繁，无法降低税负和员工个人所得税'
        }
      ],
      manageList: [
        {
          icon: require('@/assets/img/digital/digital-moyu.png'),
          class: 'moyu-text-color'
        },
        {
          icon: require('@/assets/img/digital/digital-qiye.png'),
          class: 'qiye-text-color'
        },
        {
          icon: require('@/assets/img/digital/digital-fuli.png'),
          class: 'fuli-text-color'
        }
      ],
      solutionList: [
        {
          bgImg: require('@/assets/img/digital/1.jpg')
        },
        {
          bgImg: require('@/assets/img/digital/2.jpg')
        },
        {
          bgImg: require('@/assets/img/digital/3.jpg')
        },
        {
          bgImg: require('@/assets/img/digital/4.jpg')
        }
      ]
    };
  },
  methods: {
    onMouseEnter(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
.digital {
  margin-bottom: 100px;
  .header-banner {
    height: 499px;
    width: 100%;
    background-image: url('../../assets/img/digital/logo.png');
    background-size: 100% 100%;
    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
        color: rgba(255, 255, 255, 1);
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .btn-line {
        margin-top: 100px;
        margin-left: 49px;
        border-radius: 6px;
      }
    }
  }
  &-nows {
    padding-top: 77px;
    height: 530px;
    background-color: rgba(224, 240, 255, 0.2);
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .content {
      height: 419px;
      padding-left: 49px;
      img {
        width: 50px;
        height: 50px;
      }
      .title {
        height: 21px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        margin: 20px 0 10px 0;
      }
      .desc {
        width: 294px;
        height: 21px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }
    }
  }
  &-solution {
    padding-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .content {
      // height: 419px;
      padding-top: 47px;
      .card {
        cursor: pointer;
      }
      .card-image {
        height: 368px;
        background-size: 100% 100%;
      }
      .title {
        text-align: center;
        height: 21px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        margin: 15px 0 20px 0;
      }
      .title-selected {
        color: @lupay-text-color-theme;
      }
      .icon-right {
        background-color: @lupay-text-color-theme;
        height: 1px;
        width: 0px;
        position: relative;
        z-index: 2;
        transition-duration: 300ms;
        transition-property: transform, width;
      }
      .icon-right-selected {
        width: 168px;
      }
      .icon-right-selected::after {
        content: '';
        position: absolute;
        right: 5.5px;
        top: -12px;
        display: block;
        width: 1px;
        height: 15px;
        background-color: @lupay-text-color-theme;
        transform: rotate(130deg);
        z-index: 1;
      }
      .mask {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition-duration: 1000ms;
        transition-property: transform, opacity;
        border-radius: 6px;
      }
      .mask-selected {
        width: 100%;
        height: 100%;
        background: rgba(34, 78, 219, 0.8);
        border-radius: 6px;
        opacity: 1;
      }
      .mask-content {
        padding: 40px 36px 10px 47px;
        &-title {
          position: relative;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          line-height: 40px;
          margin-left: 25px;
        }
        &-desc {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          margin-top: 20px;
          line-height: 30px;
        }
        &-title::before {
          content: '';
          position: absolute;
          display: block;
          width: 10px;
          height: 10px;
          background: rgba(61, 238, 228, 1);
          left: -25px;
          top: 15px;
          border-radius: 50%;
        }
      }
    }
    .btn-line {
      width: 126px;
      height: 43px;
      background: linear-gradient(
        125.78deg,
        @lupay-text-color-theme 0%,
        rgba(38, 81, 220, 1) 0%,
        rgba(231, 238, 252, 1) 100%
      );
      border-radius: 6px;
      margin-top: 50px;
    }
  }
  &-manage {
    padding-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .content {
      margin-top: 48px;
      .card {
        height: 322px;
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;
        cursor: pointer;
        padding: 60px 43px 20px 43px;
        img {
          max-width: 50px;
          max-height: 50px;
        }
        .digital-card-title {
          font-size: 16px;
          margin-left: 10px;
        }
        .digital-card-desc {
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          margin-top: 20px;
          line-height: 25px;
        }
        .moyu-text-color {
          color: rgba(238, 109, 11, 1);
        }
        .qiye-text-color {
          color: @lupay-text-color-theme;
        }
        .fuli-text-color {
          color: rgba(31, 185, 93, 1);
        }
      }
      .card:hover {
        box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
      }
    }
  }
}
</style>
